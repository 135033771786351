import appConfig from "../appConfig";
import sortBy from "lodash/sortBy";
/**
 * Is dev or not
 * @returns Is or not a dev environment
 */
export function isDev() {
  return appConfig.IS_DEV;
}

/**
 * Get min elements from a collection
 * @param {collection} collection An array of objects
 * @param {string} propertyName the prop name from the object to sort by
 * @param {integer} n number of elements to extract
 * @returns {array} sorted slice array
 */
export function findMinNElements(collection, propertyName, n = 1) {
  const sorted = sortBy(collection, (el) => el[propertyName]);
  return sorted.slice(0, n);
}

/**
 * Rounds value to "preciosion" numbers after ,
 *
 * @param int|float value
 * @param int precision - number of digits after int
 * @return float
 */
export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

/**
 * Open link in a new tab
 * @param {string} url
 */
export function openInNewTab(url) {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
}

/**
 * Gatsby navigation
 * @param {string} address
 */
export function navigate(address) {
  console.log("nav to", address);
  window.appRouter.goTo(address);
}
