import React from "react";
import "./Bar.scss";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// import Tooltip from "@material-ui/core/Tooltip";

export default function Bar({value, label, title, color}) {
  const b = "value-bar";

  let barClass = "";

  if (!color) {
    if (value < 40) {
      barClass = "blue";
    } else if (value < 70) {
      barClass = "yellow";
    } else {
      barClass = "red";
    }
  }

  return (
    <div className={`${b}`}>
      {/* <Tooltip className="tooltip" title="Delete"> */}
      <Typography variant="body2" color="textSecondary">
        {title}
      </Typography>
      {/* </Tooltip> */}
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress
            className={barClass}
            variant="determinate"
            value={value}
            color={color}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {label}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
