import * as React from "react";
import image from "@static/logoIulia.svg";

const Logo = () => {
  const b = "logo";
  return (
    <div className={b}>
      <a href="/">
        <img src={image} alt="diaspora voteaza" />
      </a>
    </div>
  );
};

export default Logo;
