import sections from "../dataFiles/sectii2021v2.json";
import sectionModel from "@models/sectionModel";
import update from "immutability-helper";
import {uniq} from "lodash";

// state: "Republica Italiană",
// sectionID: "1/363",
// oldSectionID: "NA",
// Localitatea: "or. Bolzano",
// Adresa: "Via Dalmazia 30/C",
// lat: "46.4919062",
// long: "11.3364408",
// "Statutul sectiei în anticipate 2021 față de turul II în 2020":
//   "Secție omisă",
// "Numărul de alegători de la prezidențiale, turul II 2020 - TII": "963",
// "Numărul de alegători înregistrați care ar participa la votare - Ip": "NA",
// "Numărul de alegători extrapolat din turul II 2020": "NA",

// "Statul": "Ucraina",
// "Nr. S.V.": "38/149",
// "Nr. S.V. vechi": "1/441",
// "Localitatea": "or. Odessa (sediul consulatului)",
// "Adresa": "or. Odessa (sediul C) 65009, or Odesa, str. Posmitîi 2/2 \"B\"",
// "lat": 46.482526,
// "long": 30.7233095,
// "Statutul secției în anticipate 2021 față de turul II în 2020": "Neschimbat",
// "Numărul de alegători de la prezidențiale, turul II 2020 - TII": "295",
// "Numărul de alegători înregistrați care ar participa la votare - Ip": 106,
// "Numărul de alegători extrapolat din turul II 2020": "NA"
// },

function normalizeSectionData(section) {
  Object.entries(sectionModel).forEach(([newKey, oldKey]) => {
    if (section[oldKey]) {
      delete Object.assign(section, {[newKey]: section[oldKey]})[oldKey];
    }
  });

  return section;
  // const mainProps = {
  // 	...pick(section, ['state', 'sectionID', 'localitatea', 'adresa', 'lat', 'lng'])
  // }
}

/**
 * Get all sections
 * @returns array with all sections
 */
export function getSectionList() {
  return sections.map((section, i) => {
    const theSection = normalizeSectionData(section);
    return theSection;
  });
}

/**
 * Search string in object
 * @param {string} search a word to search
 * @param {array} list a collection of objects
 * @returns {array} filtered collection
 */
export function filterSections(search, list) {
  // debugger;
  if (!search || search.length < 3) return list;

  return list.filter((element) => {
    return JSON.stringify(element)
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        search
          .toLocaleLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );
  });
}

//NOT USED
export function editSection(section, propName, propValue) {
  const io = update(section.parentList, {
    [section.indexInParent]: {
      [propName]: {
        $set: propValue,
      },
    },
  });

  return io;
}

/**
 * Reading from local storage
 * @param {string} key The local storage key to get data from
 * @param {mixed} defaultData return defaultData is result is false. RESULTS MAY BE FALSE
 * @returns key data results
 */
export function getLocalStorage(key, defaultData) {
  if (typeof localStorage === "undefined") return defaultData;
  let item = localStorage.getItem("voteaza__" + key);

  if (item && (item.includes("{") || item.includes("["))) {
    try {
      item = JSON.parse(item);
    } catch (e) {
      item = defaultData;
    }
  }

  return item || defaultData;
}

/**
 * Saving to local storage plus normalization
 * @param {string} key the local storage key name to save. Will have a prepand
 * @param {mixed} data data to save in local storage
 * @returns saved data
 */
export function setLocalStorage(key, data) {
  if (typeof localStorage === "undefined") return;
  let item = typeof data === "object" ? JSON.stringify(data) : data;

  localStorage.setItem("voteaza__" + key, item);
  return true;
}

/**
 * Add or remove item from array
 * @param {string|int} element the element to add/substract
 * @param {array} theArr an array to add/remove from
 * @param {bool} add add or remove element
 * @returns {array} new array
 */
export function arrayToggle(element, theArr, remove) {
  let resultArr;
  if (remove) {
    resultArr = theArr.filter((el) => el !== element);
  } else {
    resultArr = uniq([...theArr, element]);
  }

  return resultArr;
}

export function getLikedSections() {
  return getLocalStorage("likedSections", []).map((el) => parseFloat(el));
}
