import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NavigationIcon from "@material-ui/icons/Navigation";

//Like button
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Tooltip from "@material-ui/core/Tooltip";
import {arrayToggle} from "../../helpers/dataHelpers";

import {getSectionNumber, openMap} from "../../helpers/locationHelpers";
import Box from "@material-ui/core/Box";
import {QuestionAnswer} from "@material-ui/icons";
import {Map} from "@material-ui/icons";

import {useHistory} from "react-router-dom";

import ButtonGroup from "@material-ui/core/ButtonGroup";

import AllBars from "@components/Bar/AllBars";

import {CommentsCount} from "react-facebook";
import AppFacebookProvider from "../Social/AppFacebookProvider";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function ListingCard({data, number, dispatcher, state = {}, stateCoord = {}}) {
  const b = "listing-card";
  const classes = useStyles();

  const goToMap = () => {
    openMap(data.lat, data.lng, stateCoord);
  };

  const hasLike = state.likedSections.includes(data.lat);

  const addToFav = () => {
    let likedSections = arrayToggle(data.lat, state.likedSections, hasLike);
    dispatcher({likedSections});
  };

  const history = useHistory();

  return (
    <div className={`${b}`}>
      {/* <Typography
        className={`${b}__flag`}
        color="textSecondary"
        variant="h4"
        component="h2"
      >
        #{number}
      </Typography> */}
      <Card className={classes.root} variant="outlined">
        <CardContent>
          {/* <Typography variant="h4" component="h2">
            {number}
          </Typography> */}
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {data.state}
          </Typography>
          <Typography variant="h5" component="h2">
            {data.localitatea}
          </Typography>

          <Typography className={classes.pos} color="textSecondary">
            secția {data.sectionID} (secția veche{" "}
            {data.oldSectionID === "NA" ? "lipsește" : data.oldSectionID})
          </Typography>

          <Box display="flex" alignItems="center">
            <Box minWidth={35} mr={1}>
              <NavigationIcon />
            </Box>
            <Box width="100%">
              <Typography variant="body2" component="p">
                {data.adresa}
              </Typography>
            </Box>
          </Box>

          {/* <Typography
            className={`${classes.pos} ${b}__smart-index`}
            color="textSecondary"
          >
            Dificultate:{" "}
            {data.smartIndex ? round(data.smartIndex, 3) * 1000 : "Lipsește."}{" "}
            puncte.
          </Typography> */}

          <AllBars section={data} />
        </CardContent>
        <CardActions disableSpacing>
          <ButtonGroup
            color="secondary"
            aria-label="outlined secondary button group"
          >
            {!state.noStore && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<QuestionAnswer fontSize="small" />}
                size="small"
                onClick={() =>
                  history.push(`/sectii/${getSectionNumber(data)}`)
                }
              >
                DETALII, CHAT (
                <AppFacebookProvider appId="622733632236005">
                  <CommentsCount
                    href={`${window.location.origin}/sectii/${getSectionNumber(
                      data
                    )}`}
                  />
                </AppFacebookProvider>
                )
              </Button>
            )}

            <Button
              variant="contained"
              color="default"
              startIcon={<Map />}
              size="small"
              onClick={goToMap}
            >
              Itinerar
            </Button>
          </ButtonGroup>
          {!state.noStore && (
            <IconButton
              className="margin-left-auto"
              aria-label="adauga la preferate"
              onClick={addToFav}
              color={hasLike ? "primary" : "default"}
            >
              <Tooltip title="Mută în top, ca favorită">
                <FavoriteIcon />
              </Tooltip>
            </IconButton>
          )}
        </CardActions>
      </Card>
    </div>
  );
}

export default ListingCard;
