import React, {useState, useEffect} from "react";
import LocationButtons from "@components/LocationButtons";
// import TheMap from "@components/FreeMap/FreeMap";
import {getLocationCoordinates} from "@helpers/locationHelpers";
// import { getLocationCoordinates } from "../helpers/locationHelpers";

import Listing from "@components/Listing/Listing";
import {getSectionList} from "@helpers/dataHelpers";
import {store} from "@store";
import LocationLoader from "@components/LocationLoader/LocationLoader";
import {
  findClosestSections,
  setDistanceFromStart,
  setSectionIndex,
} from "../helpers/locationHelpers";
import {Typography} from "@material-ui/core";
import {setLocalStorage} from "../helpers/dataHelpers";

export default function Content() {
  const {state = {}, dispatch} = React.useContext(store);
  const [domLoaded, setDomLoaded] = useState(0);
  const [appLoader, setAppLoader] = useState(false);

  useEffect(() => {
    setDomLoaded(1);
  }, []);

  const b = "content";

  /**
   * Find location, set section list with proper details
   */
  function onLocationFound(r) {
    const sections = getSectionList();

    if (r.lat) {
      setAppLoader(false);

      const destinations = setDistanceFromStart(r.lat, r.lng, sections);
      let filteredList = findClosestSections(destinations);
      filteredList = setSectionIndex(filteredList);

      setLocalStorage("sections", filteredList);
      setLocalStorage("coord", r);

      dispatch({
        mode: "auto",
        coord: r,
        inLoad: false,
        sections: filteredList,
      });
    } else {
      setAppLoader(false);
      alert(
        "Din motive tehnice nu putem să-ți detectăm locația. Caută în boxa de mai jos manual adresa ta. Succes!"
      );
      dispatch({mode: "manual", sections});
    }
  }
  const onLocationClick = () => {
    // showing the loading effect
    setAppLoader(true);

    getLocationCoordinates().then((r) => {
      onLocationFound(r);
    });
  };

  const onManualClick = () => {
    const sections = getSectionList();
    dispatch({mode: "manual", sections});
  };

  const onManualDataFound = (response) => {
    onLocationFound(response);
  };

  if (appLoader) return <LocationLoader />;

  return (
    <div className={b} style={{opacity: domLoaded}}>
      <div className={`${b}__antet`}>
        <Typography variant="h4" component="h2" gutterBottom>
          la alegerile din 11 iulie 2021
        </Typography>
        <Typography>
          găsește ușor <strong>cea mai optimă secție de vot</strong> în
          diasporă! <br />
          Dacă nu ai posibilitate să dai permisiuni de locație, poți folosi
          căutarea manuală.
        </Typography>
      </div>
      {!state.inLoad && state.appLoaded && state.mode !== "manual" && (
        <LocationButtons
          onLocationClick={onLocationClick}
          onManualClick={onManualClick}
        />
      )}

      <Listing onManualDataFound={onManualDataFound} />
      {/* {coord.lat && <TheMap {...coord} />} */}
    </div>
  );
}
