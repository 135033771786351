import React from "react";

// import {Helmet} from "react-helmet";
import ListingCard from "@components/Listing/ListingCard";
import {
  getLikedSections,
  getLocalStorage,
  getSectionList,
} from "../helpers/dataHelpers";
import {getSectionID, getSectionNumber} from "../helpers/locationHelpers";
// import ContentWrapper from "../templates/ContentWrapper";
// import SmallLoader from "../components/SmallLoader/SmallLoader";

import {Comments} from "react-facebook";
import AppFacebookProvider from "@components/Social/AppFacebookProvider";

const FacebookChat = (props) => {
  const href = typeof window !== "undefined" ? window.location.href : "#";
  return (
    <div className={`app-fb-comments`}>
      <AppFacebookProvider>
        <Comments href={`${href}`} width="100%" language="ro_RO" />
      </AppFacebookProvider>
    </div>
  );
  // return (
  //   <>
  //     {/* <Helmet> */}
  //     {/* <script
  //       async={true}
  //       defer={true}
  //       crossOrigin="anonymous"
  //       src={`https://connect.facebook.net/ro_RO/sdk.js#xfbml=1&version=v11.0&appId=622733632236005&autoLogAppEvents=1&time=${+new Date()}`}
  //       nonce="OLbqAngY"
  //       id="fb-script"
  //     /> */}
  //     {/* </Helmet> */}
  //     <div
  //       className="fb-comments"
  //       data-href={`${window.location.href}`}
  //       data-width="100%"
  //       data-numposts="5"
  //     />

  //     <div
  //       className="fb-comment-embed"
  //       data-href={`${window.location.href}`}
  //       data-width="500"
  //     ></div>
  //   </>
  // );
};

const SomeSubPage = (props) => {
  const slug = getSectionID();

  const b = "sectii-page";
  const sections = getSectionList();

  const localSaved = getLocalStorage("sections", []);
  const likes = getLikedSections();

  let element = localSaved.find((el) => getSectionNumber(el) === slug);
  if (!element) {
    element = sections.find((el) => getSectionNumber(el) === slug);
  }

  return (
    <div className={`${b} center-flex-column`}>
      {element && (
        <ListingCard
          data={element}
          number={1}
          state={{likedSections: likes, noStore: true}}
        />
      )}
      {!element && <div className={`${b}__no-find`}>Adresa e greșită...</div>}
      <FacebookChat slug={slug} />
      {/* {element && <SmallLoader />} */}
    </div>
  );
};

export default SomeSubPage;
