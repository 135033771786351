import {Divider, Typography} from "@material-ui/core";
import * as React from "react";

const Footer = () => {
  const b = "footer";
  return (
    <div className={b}>
      <Divider light />
      <Typography
        className="margin-top-20"
        variant="h6"
        component="h6"
        gutterBottom
      >
        Alege pe 11 iulie - de tine depinde viitorul!
      </Typography>
    </div>
  );
};

export default Footer;
