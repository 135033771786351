import * as React from "react";
import "../style.scss";
import MyHeader from "@templates/Header";
import Footer from "@templates/Footer";

// markup
const ContentWrapper = (props) => {
  const b = "main-wrapper";
  return (
    <div className={b}>
      <MyHeader />
      <div className={`${b}__content`}>
        <div className={`content`}>{props.children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default ContentWrapper;
