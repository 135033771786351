import React, {useState, useEffect} from "react";
import "./Menu.scss";
import {makeStyles} from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
// import RestoreIcon from "@material-ui/icons/Restore";
// import FavoriteIcon from "@material-ui/icons/Favorite";
import Help from "@material-ui/icons/Help";
import Facebook from "@material-ui/icons/Facebook";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import {openInNewTab} from "../../helpers/miscHelpers";
import {useHistory, useLocation} from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: 500,
  },
});

export default function Menu() {
  const b = "menu";
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();

  const [domLoaded, setDomLoaded] = useState(0);

  useEffect(() => {
    setDomLoaded(1);
  }, []);

  let value = 100;
  const path = location.pathname;

  if (path === "/") {
    value = 0;
  } else if (path.includes("despre")) {
    value = 1;
  }

  return (
    <div className={`${b}`} style={{opacity: domLoaded}}>
      <BottomNavigation value={value} showLabels className={classes.root}>
        <BottomNavigationAction
          label="Acasă"
          icon={<LocationOnIcon />}
          onClick={() => history.push(`/`)}
        />
        <BottomNavigationAction
          label="Despre"
          icon={<Help />}
          onClick={() => history.push(`/despre`)}
        />
        <BottomNavigationAction
          label="Facebook"
          icon={<Facebook />}
          onClick={() =>
            openInNewTab("https://www.facebook.com/groups/voteazafaracoada")
          }
        />
      </BottomNavigation>
    </div>
  );
}
