import React from "react";
// import ContentWrapper from "@templates/ContentWrapper";

import cardScreen from "@static/card-screen.png";
import geoDist from "@static/geo-dist.png";
import inregistrare from "@static/inregistrare.png";
import turul2 from "@static/tur-2.png";
import Image from "../components/Image";

export default function Despre() {
  return (
    <>
      <h1 className="p1">Despre</h1>
      <p className="p2">
        <span className="s1">
          Salut prieteni, noi suntem o echipă de tineri care a hotăr&acirc;t să
          sară &icirc;n ajutor diasporei cu intenția de a facilita procesul de
          selectare a secției de votare cu intenția de a reduce cozile și
          asigurarea buletinelor de vot pentru toți.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          &Icirc;n platforma &bdquo;voteaza-fara-coada&rdquo;, după confirmarea
          partajării locației, veți găsi o listă de zece, cele mai apropiate
          secții de votare. Acestea sunt sortate după secțiile de vot apropiate
          și neaglomerate țin&acirc;nd cont de aglomerarea acestora &icirc;n
          turul II al scrutinului prezidențial, &icirc;nregistrările prealabile
          a alegătorilor și distanța de la tine p&acirc;nă la aceste zece
          secții.
        </span>
      </p>
      <p className="p2">
        <span className="s1">
          Adițional, la fiecare secție este implementată funcția de
          &bdquo;facebook chat&rdquo;. Această funcție este destinată
          alegătorilor care sunt deja la secția de vot și îți pot comunica cea
          mai actuală informație. Astfel alegătorii pot afla ușor, c&acirc;t de
          mare e coada, cum decurge votarea, unde este parcarea, unde este
          veceu, cine are de schimbat 100 MDL etc.
        </span>
      </p>
      <p className="p3">
        <span className="s1">
          <strong>Atenție!!!</strong> Tratează sugestiile pe care le primești cu
          prudență. Acestea sunt <strong>sugestii</strong> nu{" "}
          <strong>obligații</strong>. Astfel, noi nu garantăm și nu ne asumăm
          răspunderea că vei ajunge la o secție pustie cu 5000 de buletine de
          vot disponibile. Pentru a te ajuta să &icirc;nțelegi cum lucrează
          platforma, mai jos &icirc;ți oferim niște explicative pentru
          informația despre fiecare secție.
        </span>
      </p>
      <h3 className="p5">
        <span className="s1">Cardul secției</span>
      </h3>
      <p className="p2">
        <Image src={cardScreen} className="half-left" />
        <span className="s1">
          <span className="Apple-converted-space">&nbsp;</span>Fiecare secție
          conține un card informativ. Aici găsești informația despre secția
          respectivă, așa ca: adresa și numărul secției, butonul de chat
          &icirc;mpreună cu butonul-inimioară care te va ajuta să salvezi
          secțiile preferate &icirc;n pagina de start. Un detaliu important sunt
          barele informative care indică date importante folosite la sortarea
          secțiilor pentru. Detaliile explicative pentru fiecare bară le poți
          găsi mai jos.
        </span>
      </p>
      <h3 className="p5">
        <span className="s1">Distanța geodezică</span>
      </h3>

      <p className="p2">
        <Image src={geoDist} className="half-right" />
        <span className="s1">
          <span className="Apple-converted-space">&nbsp;</span>Această distanță
          este calculată cu ajutorul coordonatelor (latitudine și longitudine)
          obținute de la tine și coordonatele secțiilor. Din păcate, această
          distanță nu reflectă direct durata de călătorie sau lungimea
          traseului. &Icirc;nsă, aceasta este o bună estimare a acestora.
          Distanța geodezică s-a dovedit a fi o alegere bună dintre cost și
          calitatea posibilităților pe care le-am avut. Plus, asta ne permite să
          nu folosim, și să nu salvăm locația ta, după ce calculăm distanțele,
          nu mai avem nevoie de ea.
        </span>
      </p>

      <h3 className="p5">
        <span className="s1">
          Nr. alegători de la prezidențiale, turul II 2020
        </span>
      </h3>
      <p className="p2">
        <Image src={turul2} className="half-left" />
        <span className="s1">
          <span className="Apple-converted-space">&nbsp;</span>&Icirc;n echipa
          de dezbateri am avut pe cineva* (vezi &icirc;n recunoștințe) care a
          distribuit pe hartă toate secțiile din turul II al scrutinului
          prezidențial cu culorile acestora raportate la numărul de voturi.
          Astfel, &icirc;nțelegi care secții trebuie de evitat și la care s-a
          votat lejer. &Icirc;nsă, cu introducerea noilor secții, totul devine
          mai confuz pentru că imaginea din turul II devine nefolositoare. Noi
          &icirc;nsă am preluat aceste date și le-am redistribuit, lu&acirc;nd
          &icirc;n calcul și secțiile noi din rază. Astfel acest număr nu
          reflectă cu exactitate rezultatele din turul II.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>

      <h3 className="p5">
        <span className="s1">Nr. alegători &icirc;nregistrați</span>
      </h3>
      <Image src={inregistrare} className="half-right" />

      <p className="p2">
        <span className="s1">
          <span className="Apple-converted-space">&nbsp;</span>Foarte multă
          forfotă s-a făcut &icirc;n perioada c&acirc;nd &icirc;nregistrarea
          prealabilă era deschisă. Astfel &icirc;nc&acirc;t am avut aproape 100
          de mii de &icirc;nregistrări. Am hotăr&acirc;t că este un număr destul
          de reprezentativ. Av&acirc;nd acces la locațiile și numărul acestora,
          le-am distribuit la secțiile de votare din rază, cam așa cum am făcut
          și &icirc;n exemplul de mai sus. Rezultatele sunt asemănătoare cu cele
          din turul II, cu toate că &icirc;n unele cazuri avem excepții.
        </span>
      </p>
      <h3 className="p5">
        <span className="s1">Recunoștințe</span>
      </h3>
      <p className="p3">
        <span className="s1">
          Platforma care o folosești este rezultatul muncii a unei întregi
          echipe.{" "}
          <a
            href="https://www.facebook.com/groups/327330864290265/posts/1444955765861097"
            target="_blank"
            rel="noreferrer"
          >
            După ce am vociferat idea
          </a>{" "}
          la mijlocul lunii iunie pe ADOPTĂ UN VOT, ne-am adunat pentru a aduce
          acest proiect la un bun sf&acirc;rșit.
        </span>
      </p>
      <p className="p3">
        Persoane de contact, și inițiatorii proiectului -{" "}
        <a
          href="https://www.facebook.com/mitrofancic"
          rel="noreferrer"
          target="_blank"
        >
          Curti Mitrofan
        </a>{" "}
        și{" "}
        <a
          href="https://www.facebook.com/lozovan.nicolae/"
          rel="noreferrer"
          target="_blank"
        >
          Lozovan Nicolae
        </a>
        .
      </p>
      <p className="p3">
        <span className="s1">
          &Icirc;n echipa de dezbateri, care a lucrat și a pus pe c&acirc;ntar
          toate ideile ca să putem alege ceva relevant simplu și realistic, au
          participat:
        </span>
      </p>
      <ul className="ul1">
        <li className="li3">
          <span className="s1">Alexandru Cornogolub</span>
        </li>
        <li className="li3">
          <span className="s1">Ana Daraban</span>
        </li>
        <li className="li3">
          <span className="s1">Anatol Mazur</span>
        </li>
        <li className="li3">
          <span className="s1">Nicu Soare*</span>
        </li>
        <li className="li3">
          <span className="s1">Valeria Br&acirc;nză</span>
        </li>
      </ul>
      <p className="p3">
        <span className="s1">
          &Icirc;n echipa de developeri ai platformei, au lucrat și lucrează de
          zor:
        </span>
      </p>
      <ul className="ul1">
        <li className="li3">
          <span className="s1">Nicolae Lozovan</span>
        </li>
        <li className="li3">
          <span className="s1">Nikita Sinitin</span>
        </li>
      </ul>
      <p className="p3">
        <span className="s1">
          La prelucrarea datelor, scrierea acestei pagini, precum și alte
          chestii mărunte:
        </span>
      </p>
      <ul className="ul1">
        <li className="li3">
          <span className="s1">Mitrofan Curti</span>
        </li>
      </ul>
      <p className="p3">
        <span className="s1">
          Conceptul design-ului pentru logo și denumire
        </span>
      </p>
      <ul className="ul1">
        <li className="li3">
          <span className="s1">Iulia Zimagorov</span>
        </li>
        <li className="li3">
          <span className="s1">Ana Azarov</span>
        </li>
      </ul>
    </>
  );
}
