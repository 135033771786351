const appConfig = {
  // DEV_COORD: {
  // 	lat: 47.010452,
  // 	lng: 28.86381,
  // },

  // UK
  DEV_COORD: {
    lat: 52.2642128,
    lng: -1.1830866,
  },

  IS_DEV: false,
  MAPS_API_KEY: "",
  GEO_CODER_KEY: "V6HPePj40MXZhTq5F7zojc-bByTcgsVnRtrFm21XpKE",
};

export default appConfig;
