import * as React from "react";
import Content from "@templates/Content";
import {StateProvider} from "@store";
import ContentWrapper from "@templates/ContentWrapper";
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";

import Sectii from "@pages/sectii";
import Despre from "@pages/despre";

// markup
const IndexPage = () => {
  return (
    <StateProvider>
      <Router>
        <ContentWrapper>
          <Switch>
            <Route exact path="/">
              <Content />
            </Route>
            <Route path="/sectii">
              <Sectii />
            </Route>
            <Route path="/despre">
              <Despre />
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </ContentWrapper>
      </Router>
    </StateProvider>
  );
};

export default IndexPage;
