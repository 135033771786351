import React, {createContext, useReducer} from "react";
import update from "immutability-helper";
import {
  getLikedSections,
  getLocalStorage,
  setLocalStorage,
} from "../helpers/dataHelpers";

const initialCoord = getLocalStorage("coord", {});

const initialState = {
  sections: getLocalStorage("sections", []),
  likedSections: getLikedSections(),
  search: "",
  appLoaded: true,
  inLoad: false,
  coord: {...initialCoord},
  mode: initialCoord.lat ? "auto" : "none", //'auto', 'manual' - searching or auto. Default: 'none'
};

const store = createContext(initialState);
const {Provider} = store;

//Local storage saved keys
const persistentData = ["likedSections"];

const StateProvider = ({children}) => {
  const [state, dispatch] = useReducer((state, action) => {
    let newState = state;

    switch (action.type) {
      default:
        // if no action type provided, attempt to behave as regular setState
        Object.keys(action).forEach(
          (key) =>
            (newState = update(newState, {
              [key]: {$set: action[key]},
            }))
        );
    }

    // Storing to local storage
    Object.keys(action).forEach((stateName) => {
      if (persistentData.includes(stateName)) {
        setLocalStorage(stateName, newState[stateName]);
      }
    });

    return newState;
  }, initialState);

  return <Provider value={{state, dispatch}}>{children}</Provider>;
};

export {store, StateProvider};
