import appConfig from "../appConfig";
import {findMinNElements, isDev} from "./miscHelpers";
import sortBy from "lodash/sortBy";

/**
 * Getting coordinates from browser
 * @returns {object} coordinates from the browser
 */
export function getLocationRights() {
  return new Promise((resolve, reject) => {
    function success(position) {
      resolve(position);
    }
    function error() {
      resolve(false);
    }
    if (!navigator.geolocation) {
      resolve(false);
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  });
}

/**
 * Get lat and long from position.
 * @param {object} position result from browser coordinates request
 * @returns {object}
 */
export function getCoordinates(position) {
  return {
    lat: position?.coords?.latitude || false,
    lng: position?.coords?.longitude || false,
  };
}

/**
 * Returns coordinates
 * @returns {Promise} {lat, lng}
 */
export function getLocationCoordinates() {
  if (isDev()) return Promise.resolve(appConfig.DEV_COORD);

  return getLocationRights().then(getCoordinates);
}

let radians = function (degree) {
  // degrees to radians
  let rad = (degree * Math.PI) / 180;

  return rad;
};

/**
 * Getting geographic line distance between 2 points
 * @param {number} lat1 Latitude start point
 * @param {number} lon1 Longitude start point
 * @param {number} lat2 Lat finish point
 * @param {number} lon2 Long finish point
 * @returns {number} radians
 */
const haversine = (lat1, lon1, lat2, lon2) => {
  let dlat, dlon, a, c, R;

  R = 6372.8; // km
  dlat = radians(lat2 - lat1);
  dlon = radians(lon2 - lon1);
  lat1 = radians(lat1);
  lat2 = radians(lat2);
  a =
    Math.sin(dlat / 2) * Math.sin(dlat / 2) +
    Math.sin(dlon / 2) * Math.sin(dlon / 2) * Math.cos(lat1) * Math.cos(lat2);
  c = 2 * Math.asin(Math.sqrt(a));
  return R * c;
};

/**
 * Sets geometric sferic distance between a start point and an array of locations
 * @param {float} startLat center LAT
 * @param {float} startLng center Long
 * @param {array} destinationArr collection to search values in
 * @returns {array} collection with "distance" set
 */
export let setDistanceFromStart = function (
  startLat,
  startLng,
  destinationArr
) {
  const harnesResult = [];

  destinationArr.forEach((destination) => {
    const distance = haversine(
      startLat,
      startLng,
      destination.lat,
      destination.lng
    );
    harnesResult.push({...destination, distance});
  });

  return harnesResult;
};

/**
 * TODO: extend this function to something larger
 * Returns sections sorted in a smart way.
 * @param {array} sections Collection of objects
 * @returns array
 */
export function findClosestSections(sections) {
  return findMinNElements(sections, "distance", 10);
}

/**
 * Get section number
 * @param {object|string} section
 * @returns string '45'
 */
export function getSectionNumber(section) {
  if (section.sectionID) {
    return section.sectionID.split("/")[1];
  }
  return section.split("/")[1];
}

/**
 * Calculated the smart index
 * @param {array} shortList The section list filtered by distance
 * @returns
 */
export function setSectionIndex(shortList) {
  const maxValues = {
    distance: 0,
    voteNumberT2: 0,
    voteNumberIp: 0,
    voteNumberExtrapT2: 0,
  };

  // Setting maximum value for all
  shortList.forEach((section) => {
    Object.keys(maxValues).forEach((key) => {
      // Convert to float
      section[key] = parseFloat(section[key]);

      // Clear NA values
      if (parseFloat(section[key]) && section[key] > maxValues[key])
        maxValues[key] = section[key];
    });
  });

  shortList.forEach((section, i) => {
    section.maxValues = maxValues;
    // Ignore "NA" values
    let t2 = section.voteNumberT2
      ? section.voteNumberT2 / maxValues.voteNumberT2
      : 0;
    let ip = section.voteNumberIp
      ? section.voteNumberIp / maxValues.voteNumberIp
      : 0;
    let et2 = section.voteNumberExtrapT2
      ? section.voteNumberExtrapT2 / maxValues.voteNumberExtrapT2
      : 0;

    let distance = section.distance / maxValues.distance;

    const smartIndex = distance + t2 * 0 + ip + et2;

    section.smartIndex = smartIndex / 3;
  });

  let newArr = sortBy(shortList, (el) => el.smartIndex * 10000);
  return newArr;
}

/**
 * Open map with pointer and, if exists, creates the route to the object
 * @param {float} latTo Latitude
 * @param {float} lngTo longitude
 * @param {object} state the location of the current person
 */
export function openMap(latTo, lngTo, state = {}) {
  if (
    /* if we're on iOS, open in Apple Maps */
    navigator.platform.indexOf("iPhone") !== -1 ||
    navigator.platform.indexOf("iPad") !== -1 ||
    navigator.platform.indexOf("iPod") !== -1
  ) {
    if (state.lat > 0 && state.lng > 0) {
      window.location = "maps://maps.apple.com/?q=" + latTo + "," + lngTo;
    } else {
      window.open(
        "maps://maps.google.com/maps?daddr=" + latTo + "," + lngTo + "&amp;ll="
      );
    }
  } else {
    if (state.lat > 0 && state.lng > 0) {
      window.open(
        "https://www.google.com/maps/dir/" +
          state.lat +
          "," +
          state.lng +
          "/" +
          latTo +
          "," +
          lngTo
      );
    } else {
      window.open(
        "https://maps.google.com/maps?daddr=" + latTo + "," + lngTo + "&amp;ll="
      );
    }
  }
}

/**
 * Returns section number from URL
 * @returns string
 */
export function getSectionID() {
  if (window.location.pathname.startsWith("/sectii/")) {
    return window.location.pathname.split("/").pop();
  }

  return false;
}
