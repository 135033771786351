import * as React from "react";
import Logo from "@components/Logo";
// import {Helmet} from "react-helmet";
// import logoImage from "@static/logoIulia.svg";
// import metaImage from "@static/banner.jpg";
import Menu from "../components/Menu/Menu";

// const url = "https://voteaza-fara-coada.eu";
// const staticLink = "https://voteaza-fara-coada.eu/static";
// const logoLink = `${url}${logoImage}`;
// const metaLink = `${url}${metaImage}`;
// const description = `Găsește ușor cea mai optimă secție de vot, in diasporă`;
// const title = `Votează fară coadă`;

// const Head = () => {
//   return (
//     <Helmet>
//       <title> Diaspora votează! </title>
//       <link rel="shortcut icon" type="image/jpg" href={metaLink} />

//       {/* <!-- Primary Meta Tags --> */}
//       <title>{title}</title>
//       <meta name="title" content={title} />
//       <meta name="description" content={description} />

//       {/* <!-- Open Graph / Facebook --> */}
//       <meta property="og:type" content="website" />
//       <meta property="og:url" content={url} />
//       <meta property="og:title" content={title} />
//       <meta property="og:description" content={description} />
//       <meta property="logo" content={logoImage} />
//       <meta property="og:image" content={metaLink} />

//       {/* <!-- Twitter --> */}
//       <meta property="twitter:card" content="summary_large_image" />
//       <meta property="twitter:url" content={url} />
//       <meta property="twitter:title" content={title} />
//       <meta property="twitter:description" content={description} />
//       <meta property="twitter:image" content={metaLink} />

//       {/* Fonts */}
//       <link rel="preconnect" href="https://fonts.googleapis.com" />
//       <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
//       <link
//         href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;1,900&display=swap"
//         rel="stylesheet"
//       />
//       {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
//       <script
//         async
//         src="https://www.googletagmanager.com/gtag/js?id=G-6HRWYCKRYE"
//       ></script>
//     </Helmet>
//   );
// };

const MyHeader = () => {
  const b = "header";

  // Google tag manager
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-6HRWYCKRYE");
    }
  });
  return (
    <>
      <div id="fb-root"></div>
      {/* <Head /> */}
      <div className={b}>
        <Logo />
        <Menu />
      </div>
    </>
  );
};

export default MyHeader;
