import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import appConfig from "../../appConfig";
import match from "autosuggest-highlight/match";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function SearchAddress({changeHandler}) {
  const apikey = appConfig.GEO_CODER_KEY;
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([""]);

  async function getGeocode(locationId) {
    try {
      const result = await fetch(
        `https://geocoder.ls.hereapi.com/6.2/geocode.json?locationid=${locationId}&jsonattributes=1&gen=1&jsonattributes=1&apiKey=${apikey}`
      );
      return await result.json();
    } catch (error) {
      alert("Eroare in cautarea locatiei dupa adresa!");
      console.error(error);
    }
  }

  const getPredictions = React.useMemo(() => {
    const getAddressByInput = async function (address) {
      try {
        const result = await fetch(
          `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${apikey}&query=${address}&maxresults=5`
        );
        return await result.json();
      } catch (error) {
        console.error(error);
        alert("Eroare in cautarea adresei!");
      }
    };

    return throttle((request, callback) => {
      getAddressByInput(request.input).then((results) => {
        callback(results.suggestions);
      });
    }, 200);
  }, [apikey]);

  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    getPredictions({input: inputValue}, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, getPredictions]);

  return (
    <Autocomplete
      className="fullWidth"
      variant="outlined"
      style={{width: "100%", maxWidth: "100%"}}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.label
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        // debugger;
        if (newValue) {
          setOptions(newValue ? [newValue.label, ...options] : options);
          setValue(newValue.label);
          getGeocode(newValue.locationId).then((addressDetail) => {
            const {latitude, longitude} = addressDetail?.response?.view[0]
              ?.result[0]?.location?.displayPosition || {
              lat: false,
              lng: false,
            };

            changeHandler({
              lat: latitude,
              lng: longitude,
              address: newValue.label,
            });
          });
        } else {
          setOptions([]);
          setValue("");
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Caută după adresa ta, eg. London Street 48"
          variant="outlined"
        />
      )}
      renderOption={(option) => {
        const matches = match(option.label, inputValue);
        const parts = parse(option.label, matches);

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{fontWeight: part.highlight ? 700 : 400}}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.label}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
