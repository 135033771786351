import React from "react";
import "./LocationButtons.scss";
import {makeStyles} from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import NavigationIcon from "@material-ui/icons/Navigation";

import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function LocationButtons({onLocationClick, onManualClick}) {
  const classes = useStyles();
  const b = "location-buttons";

  return (
    <div className={`${b}`}>
      <div className={classes.root}>
        <Fab variant="extended" color="primary" onClick={onLocationClick}>
          <NavigationIcon className={classes.extendedIcon} />
          Caută automat
        </Fab>
        <Button onClick={onManualClick}>
          <EditIcon />
          Caută manual
        </Button>
      </div>
    </div>
  );
}
