import React from "react";
import {store} from "@store";
import Typography from "@material-ui/core/Typography";

import "./Listing.scss";
import ListingCard from "./ListingCard";

import SearchAddress from "./SearchAddress";

function Listing(props) {
  const b = "section-list";

  const {state = {}, dispatch} = React.useContext(store);

  let list = state.sections;

  if (state.mode === "none") return null;

  if (state.mode === "manual") {
    list = []; // filterSections(state.search, list);
  }

  let text = "";
  if (state.mode === "manual") {
    text =
      "Introdu detalii cât mai exacte, despre locul unde te afli, pentru a putea căuta cele mai optime secții de vot.";
  } else if (state.mode === "auto") {
    text = `Acestea sunt cele mai potrivite secții de vot`;

    if (state.coord?.address) {
      text += `, pentru "${state.coord.address}"`;
    }
  }

  const nonLiked = list.filter((el) => !state.likedSections.includes(el.lat));
  const liked = list.filter((el) => state.likedSections.includes(el.lat));

  const likedThenNeutral = [...liked, ...nonLiked];
  return (
    <div className={`${b}`}>
      {state.mode === "manual" && (
        // <Search onSearch={onSearch} value={state.search} />
        <SearchAddress changeHandler={props.onManualDataFound} />
      )}

      <div className={`${b}__top-label`}>
        <Typography gutterBottom>{text}</Typography>
      </div>

      {likedThenNeutral.map((element, i) => {
        return (
          <ListingCard
            key={i}
            data={element}
            number={i + 1}
            stateCoord={state.coord}
            dispatcher={dispatch}
            state={state}
          />
        );
      })}
    </div>
  );
}

export default Listing;
