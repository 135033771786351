import React from "react";
import Bar from "@components/Bar/Bar";
import {round} from "@helpers/miscHelpers";
import "./Bar.scss";

export default function AllBars({section}) {
  const b = "all-bars";

  if (section.voteNumberT2 === "NA") section.voteNumberT2 = 0;

  // const voteNumberT2Percent = (section.voteNumberT2 * 100) / 5000;
  const voteNumberIpPercent = (section.voteNumberIp * 100) / 5000;
  const voteNumberExtrapT2Percent = (section.voteNumberExtrapT2 * 100) / 5000;

  let distancePercent;
  if (section.distance) {
    distancePercent = (section.distance * 100) / section.maxValues.distance;
  }

  return (
    <div className={`${b}`}>
      <Bar
        value={
          voteNumberExtrapT2Percent > 100 ? 100 : voteNumberExtrapT2Percent || 0
        }
        label={section.voteNumberExtrapT2 || "N/A"}
        title="Alegătorii din turul II 2020 redistribuiți"
      />
      <Bar
        value={voteNumberIpPercent > 100 ? 100 : voteNumberIpPercent}
        label={section.voteNumberIp || "N/A"}
        title="Alegătorii înregistrați care ar veni aici"
      />
      {section.distance && (
        <Bar
          value={distancePercent}
          label={round(section.distance) + "km"}
          title="Distanță geodezică până la secție"
        />
      )}
    </div>
  );
}
